import { AxiosInstance } from 'axios'
import { ApiResult, idItem, Pagging } from '../server.api'

// Api interface
export default class BoccApi {
  instance: AxiosInstance

  constructor(instance: AxiosInstance) {
    this.instance = instance
  }

  async search(searchParams: SearchBoccText): Promise<ApiResult<Pagging<BoccTextBase>>> {
    return this.instance.post('/Bocc/Search', searchParams)
  }

  async linkThemeToText(toLink: LinkBoccTextWithTheme): Promise<ApiResult<BoccTextBase>> {
    return this.instance.post(`/Bocc/LinkBoccTheme`, toLink)
  }

  async getBoccFileTempUrl(fileId: number): Promise<ApiResult<string>> {
    return this.instance.get(`/Bocc/GetBoccFileTempUrl/${fileId}`)
  }

  async searchTheme(searchParams: BoccTextThemeSearchDto): Promise<ApiResult<Pagging<BoccTextThemeBase>>> {
    return this.instance.post('/BoccTextTheme/Search', searchParams)
  }

  async getTextTheme(themeId: number): Promise<ApiResult<BoccTextThemeComplete>> {
    return this.instance.get(`/BoccTextTheme/${themeId}`)
  }

  async updateTextTheme(theme: BoccTextThemeUpdateDto): Promise<ApiResult<idItem>> {
    return this.instance.post(`/BoccTextTheme/Update`, theme)
  }

  async createTextTheme(theme: BoccTextThemeCreateDto): Promise<ApiResult<idItem>> {
    return this.instance.post(`/BoccTextTheme/Create`, theme)
  }

  async deleteTextTheme(themeId: number): Promise<ApiResult<idItem>> {
    return this.instance.delete(`/BoccTextTheme`, { params: { themeId }})
  }
}

// Types
type BoccTextBase = {
  id: number
  title?: string
  date?: string
  ccnIds?: number[]
  ccnCodes?: string[]
  fileId: number
  boccId: number
  boccLegifranceId?: string
  primaryThemes?: BoccTextThemeBase[]
  secondaryThemes?: BoccTextThemeBase[]
}

enum BoccTextSortableKey {
  Id = 'Id',
  Title = 'Title',
  Parution = 'Parution',
}

type BoccTextThemeBase = {
  id: number
  name: string
  primary: boolean
  parentId?: number
}

type SearchBoccText = {
  page: number
  limit: number
  ccnIds?: number
  afterDate?: Date
  beforeDate?: Date
  title?: string
  withTheme?: boolean
  orderByDes: boolean
  sortBy: BoccTextSortableKey
}

type BoccTextThemeCreateDto = {
  name: string
  primary: boolean
  parentId?: number
}

type BoccTextThemeSearchDto = {
  page: number
  limit: number
  orderByDes: boolean
  name?: string
  parentId?: number
}

type BoccTextThemeUpdateDto = {
  name: string
  primary: boolean
  parentId?: number
  id: number
}

type BoccTextThemeComplete = {
  id: number
  name: string
  primary: boolean
  parentId?: number
  parent?: BoccTextThemeBase
}


type LinkBoccTextWithTheme = {
  textId:number
  themeIds: number[]
}

export {
  SearchBoccText,
  BoccTextBase,
  BoccTextSortableKey,
  BoccTextThemeBase,
  BoccTextThemeCreateDto,
  BoccTextThemeSearchDto,
  BoccTextThemeUpdateDto,
  BoccTextThemeComplete,
}
