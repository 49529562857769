import axios, { AxiosInstance, AxiosResponse } from 'axios'
import CcnModuleApi from './modules/ccn.module.api'
import ApeModuleApi from './modules/ape.module.api'
import AssocApeCcnApi from './modules/assocApeCcn.module.api'
import SanteCategoryApi from './modules/santeCategory.modue.api'
import SantePrestationApi from './modules/santePrestation.module.api'
import SanteAccordApi from './modules/santeAccord.module.api'
import SanteNomenclatureApi from './modules/santeNomenclature.module.api'
import SalaireCategoryApi from './modules/salaireCategory.modue.api'
import SalairePrestationApi from './modules/salairePrestation.module.api'
import SalaireAccordApi from './modules/salaireAccord.module.api'
import SalaireNomenclatureApi from './modules/salaireNomenclature.module.api'
import RetraiteCategoryApi from './modules/retraiteCategory.modue.api'
import RetraitePrestationApi from './modules/retraitePrestation.module.api'
import RetraiteAccordApi from './modules/retraiteAccord.module.api'
import RetraiteNomenclatureApi from './modules/retraiteNomenclature.module.api'
import CibleApi from './modules/cible.module.api'
import UniteApi from './modules/unite.module.api'
import PrevoyanceNomenclatureApi from './modules/prevoyanceNomenclature.module.api'
import PrevoyanceAccordApi from './modules/prevoyanceAccord.module.api'
import PrevoyanceCategoryApi from './modules/prevoyanceCategory.module.api'
import PrevoyancePrestationApi from './modules/prevoyancePrestation.module.api'
import UserModuleApi from './modules/user.module.api'
import NewsApi from './modules/news.module.api'
import RoleModuleApi from './modules/role.module.api'
import AbonnementModuleApi from './modules/abonnement.module.api'
import CompanyApi from './modules/company.module.api'
import AuthApi from './modules/auth.module.api'
import BoutiqueApi from './modules/boutique.module'
import AuthHeaderInterceptor from './interceptor/authHeader.interceptor'
import AuthResponseInterceptor from './interceptor/authResponse.interceptor'
import ExterneApiApi from './modules/externeApi.module.api'
import { env } from '@/env'
import BoccApi from '@/api/modules/bocc.module.api'

export default class ApiServer {
  public urlApi: string
  public urlOpenId: string
  public urlConnect: string
  public urlPayment: string

  private instanceApi: AxiosInstance
  private instanceOpenId: AxiosInstance
  private instanceConnect: AxiosInstance
  private instancePayment: AxiosInstance

  public ape: ApeModuleApi
  public ccn: CcnModuleApi
  public bocc: BoccApi
  public assocApeCcn: AssocApeCcnApi
  public prevoyanceAccord: PrevoyanceAccordApi
  public prevoyanceCategory: PrevoyanceCategoryApi
  public prevoyanceNomenclature: PrevoyanceNomenclatureApi
  public prevoyancePrestation: PrevoyancePrestationApi
  public santeAccord: SanteAccordApi
  public santeCategory: SanteCategoryApi
  public santeNomenclature: SanteNomenclatureApi
  public santePrestation: SantePrestationApi
  public salaireAccord: SalaireAccordApi
  public salaireCategory: SalaireCategoryApi
  public salaireNomenclature: SalaireNomenclatureApi
  public salairePrestation: SalairePrestationApi
  public retraiteAccord: RetraiteAccordApi
  public retraiteCategory: RetraiteCategoryApi
  public retraiteNomenclature: RetraiteNomenclatureApi
  public retraitePrestation: RetraitePrestationApi
  public cible: CibleApi
  public unite: UniteApi
  public user: UserModuleApi
  public news: NewsApi
  public externeApi: ExterneApiApi
  public role: RoleModuleApi
  public abonnement: AbonnementModuleApi
  public company: CompanyApi
  public auth: AuthApi
  public boutique: BoutiqueApi

  constructor() {
    this.urlApi = env.VUE_APP_API_URL
    this.urlOpenId = env.VUE_APP_API_URL_USER
    this.urlConnect = env.VUE_APP_API_URL_CONNECT
    this.urlPayment = env.VUE_APP_API_URL_PAYMENT

    this.instanceApi = axios.create({
      baseURL: this.urlApi,
      withCredentials: true,
    })
    this.instanceOpenId = axios.create({
      baseURL: this.urlOpenId,
      withCredentials: true,
    })
    this.instanceConnect = axios.create({
      baseURL: this.urlConnect,
      withCredentials: true,
    })
    this.instancePayment = axios.create({
      baseURL: this.urlPayment,
      withCredentials: true,
    })

    const list = ['instanceApi', 'instanceOpenId', 'instancePayment']
    for (const name of list) {
      ;(this as any)[name].interceptors.request.use(
        (config: any) => AuthHeaderInterceptor(config),
        (error: any) => Promise.reject(error)
      )
      ;(this as any)[name].interceptors.response.use(
        (res: any) => res,
        (error: any) => AuthResponseInterceptor(error)
      )
    }

    this.ape = new ApeModuleApi(this.instanceApi)
    this.ccn = new CcnModuleApi(this.instanceApi)
    this.bocc = new BoccApi(this.instanceApi)
    this.assocApeCcn = new AssocApeCcnApi(this.instanceApi)
    this.prevoyanceAccord = new PrevoyanceAccordApi(this.instanceApi)
    this.prevoyanceCategory = new PrevoyanceCategoryApi(this.instanceApi)
    this.prevoyanceNomenclature = new PrevoyanceNomenclatureApi(this.instanceApi)
    this.prevoyancePrestation = new PrevoyancePrestationApi(this.instanceApi)
    this.santeAccord = new SanteAccordApi(this.instanceApi)
    this.santePrestation = new SantePrestationApi(this.instanceApi)
    this.santeNomenclature = new SanteNomenclatureApi(this.instanceApi)
    this.santeCategory = new SanteCategoryApi(this.instanceApi)
    this.salaireAccord = new SalaireAccordApi(this.instanceApi)
    this.salairePrestation = new SalairePrestationApi(this.instanceApi)
    this.salaireNomenclature = new SalaireNomenclatureApi(this.instanceApi)
    this.salaireCategory = new SalaireCategoryApi(this.instanceApi)
    this.retraiteAccord = new RetraiteAccordApi(this.instanceApi)
    this.retraitePrestation = new RetraitePrestationApi(this.instanceApi)
    this.retraiteNomenclature = new RetraiteNomenclatureApi(this.instanceApi)
    this.retraiteCategory = new RetraiteCategoryApi(this.instanceApi)
    this.cible = new CibleApi(this.instanceApi)
    this.unite = new UniteApi(this.instanceApi)
    this.boutique = new BoutiqueApi(this.instancePayment)
    this.news = new NewsApi(this.instanceApi)
    this.externeApi = new ExterneApiApi(this.instanceApi)

    //
    this.user = new UserModuleApi(this.instanceOpenId)
    this.role = new RoleModuleApi(this.instanceOpenId)
    this.abonnement = new AbonnementModuleApi(this.instanceOpenId)
    this.company = new CompanyApi(this.instanceOpenId)

    //
    this.auth = new AuthApi(this.instanceConnect)
  }
}

const apiServer = new ApiServer()

export { apiServer }

type idItem = {
  id: number
}

type ServiceResponse<T> = {
  data: T
  success: boolean
  message: string
}

type ApiResult<T> = AxiosResponse<ServiceResponse<T>>

type Pagging<T> = {
  page: number
  limit: number
  count: number
  total: number
  items: T[]
}

export { idItem, ServiceResponse, ApiResult, Pagging }
